

















































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'

import {Attachment, SplashButtons, SubscriptionPlan} from '@/lib/kepler/interfaces'
import {TermOfServicesState} from '@/store/modules/termOfServices'
import {ProfileState} from '@/store/modules/profile'
import {EventBus} from '@/main'
import Utils from '@/utils'

import {FlowInputsState} from '@/store/modules/flowInputs'
import {VMenu, VSlider} from 'vuetify/lib'
import ImageCrop from '@/components/ImageCrop.vue'
import CameraDialog from '@/views/uploader_flow/CameraDialog.vue'
import CameraPreset from '@/lib/camera/cameraPresets'
import {blobToDataURL} from '@/lib/BlobHelper'
import {LogColorByLevel} from '@/lib/plugins/logger'
import moment from 'moment'
import QrScanner from '@/components/QrScanner.vue'
import Splash from '@/views/Splash.vue'

@Component({
  components:
    {
      DevButtons: Utils.loadView('Dev/DevButtons'),
      DevDialogs: Utils.loadView('Dev/DevDialogs'),
      Branding: Utils.loadComponent('dev/Branding'),
      VehicleIcon: Utils.loadComponent('VehicleIcon'),
      ListTileTitle: Utils.loadComponent('proxy/List/ListTileTitle'),
      ListTile: Utils.loadComponent('proxy/List/ListTile'),
      List: Utils.loadComponent('proxy/List/List'),
      Sheet: Utils.loadComponent('proxy/Sheet'),
      CameraButton: Utils.loadComponent('uploader/CameraButton'),
      Flex: Utils.loadComponent('proxy/Flex'),
      Img: Utils.loadComponent('proxy/Image'),
      Checklist: Utils.loadComponent('Checklist'),
      ChecklistItem: Utils.loadComponent('ChecklistItem'),
      AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
      Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
      TextField: Utils.loadComponent('proxy/Inputs/TextField'),
      SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
      PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
      CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
      CardWithReport: Utils.loadComponent('CardWithReport'),
      CardText: Utils.loadComponent('proxy/Card/CardText'),
      TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
      MonthSwitcher: Utils.loadComponent('MonthSwitcher'),
      TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
      Container: Utils.loadComponent('proxy/Container'),
      GradientCard: Utils.loadComponent('GradientCard'),
      StatusLabel: Utils.loadComponent('StatusLabel'),
      FlowTest: Utils.loadComponent('flow/FlowTest'),
      CustomIcon: Utils.loadComponent('CustomIcon'),
      Divider: Utils.loadComponent('proxy/Divider'),
      Card: Utils.loadComponent('proxy/Card/Card'),
      Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
      Layout: Utils.loadComponent('proxy/Layout'),
      LoopingBG: Utils.loadComponent('LoopingBG'),
      Avatar: Utils.loadComponent('proxy/Avatar'),
      Tab: Utils.loadComponent('proxy/Tabs/Tab'),
      Badge: Utils.loadComponent('proxy/Badge'),
      Chip: Utils.loadComponent('proxy/Chip'),
      Icon: Utils.loadComponent('proxy/Icon'),
      Btn: Utils.loadComponent('proxy/Btn'),
      TopBar: Utils.loadComponent('TopBar'),
      Button: Utils.loadComponent('Button'),
      CameraDialog,
      ImageCrop,
      VSlider,
      VMenu,
    },
})

export default class Dev extends Vue {
  @State('profile') protected profileState!: ProfileState
  @State('termOfServices') protected TermsOfServiceState!: TermOfServicesState
  @State('flowInputs') protected flowInputs!: FlowInputsState
  @State((state) => state.logs) protected logs!: string[]

  @Action('getPlans') protected getPlans!: () => Promise<SubscriptionPlan[]>
  @Action('setDebugMode') protected setDebugMode!: (enabled: boolean) => void
  @Action('setTranslationMode') protected setTranslationMode!: (enabled: boolean) => void
  @Action('setDebugTab') protected setTab!: (tab: number) => void
  @Action('addProfilePicture') protected addProfilePicture!: any
  @Action('clearLogs') protected clearLogs!: () => void
  @Action('toggleDevFlow') protected toggleDevFlow!: () => void
  @Action('switchProfile') protected switchProfile!: (token: string) => Promise<void>
  @Action('setSplash') protected setSplashConfig!: (config: SplashButtons[]) => void

  @Getter('debugTab') protected debugTab!: number
  @Getter('debugMode') protected debugMode!: boolean
  @Getter('translationMode') protected translationMode!: boolean
  protected reversedLogs: boolean = true
  protected flowName: string = ''
  protected flowPage: number = 0
  protected cameraPresets = CameraPreset.prototype.getPresets()
  protected cameraResult: string | null = null
  protected cameraResultToken: string | null = null
  protected uploadResultUrl: string | null = null
  protected qrContent: string = ''
  protected token: string = ''
  protected tokenSwitching: boolean = false

  protected tabs = [
    'misc',
    'camera',
    'dialogs/popups',
    'flow',
    'branding',
    'typography',
    'buttons',
    'animated background',
    'log',
    'token',
  ]

  protected icons = [
    'mdi-account',
    'mdi-account-circle',
    'mdi-account-group',
    'mdi-account-multiple',
    'mdi-account-multiple-plus',
    'mdi-account-plus',
    'mdi-air-conditioner',
    'mdi-alert',
    'mdi-alert-circle-outline',
    'mdi-arrow-left',
    'mdi-arrow-right',
    'mdi-arrow-right-bold',
    'mdi-backspace',
    'mdi-bag-carry-on',
    'mdi-bag-personal',
    'mdi-bag-suitcase',
    'mdi-bluetooth',
    'mdi-calendar',
    'mdi-calendar-arrow-right',
    'mdi-calendar-check',
    'mdi-calendar-clock',
    'mdi-calendar-text',
    'mdi-calendar-today',
    'mdi-camera',
    'mdi-camera-plus-outline',
    'mdi-camera_alt',
    'mdi-cancel',
    'mdi-car',
    'mdi-car-battery',
    'mdi-car-child-seat',
    'mdi-car-door',
    'mdi-car-key',
    'mdi-car-off',
    'mdi-car-seat',
    'mdi-car-shift-pattern',
    'mdi-card-account-details',
    'mdi-cards-variant',
    'mdi-cash',
    'mdi-cash-multiple',
    'mdi-check',
    'mdi-check-bold',
    'mdi-checkbox-blank-outline',
    'mdi-checkbox-marked',
    'mdi-chevron-double-down',
    'mdi-chevron-down',
    'mdi-chevron-left',
    'mdi-chevron-right',
    'mdi-chevron-up',
    'mdi-clear',
    'mdi-clock-alert-outline',
    'mdi-clock-outline',
    'mdi-close',
    'mdi-close-circle-outline',
    'mdi-cloud-sync',
    'mdi-cloud-upload',
    'mdi-cog',
    'mdi-content-copy',
    'mdi-content-save',
    'mdi-credit-card-multiple',
    'mdi-credit-card-outline',
    'mdi-crosshairs-gps',
    'mdi-currency-usd-circle-outline',
    'mdi-delete ',
    'mdi-directions',
    'mdi-done',
    'mdi-eraser-variant',
    'mdi-eye',
    'mdi-eye-off',
    'mdi-face-agent',
    'mdi-file',
    'mdi-file-document-edit',
    'mdi-file-download',
    'mdi-file-pdf',
    'mdi-filter-variant',
    'mdi-flash',
    'mdi-flash-off',
    'mdi-folder',
    'mdi-format-rotate-90',
    'mdi-forum',
    'mdi-frequently-asked-questions',
    'mdi-gas-station',
    'mdi-gauge',
    'mdi-gauge-empty',
    'mdi-headset',
    'mdi-help-circle-outline',
    'mdi-information',
    'mdi-information-variant',
    'mdi-key-variant',
    'mdi-key-wireless',
    'mdi-lock',
    'mdi-lock-outline',
    'mdi-lock-reset',
    'mdi-logout',
    'mdi-map',
    'mdi-map-marker',
    'mdi-map-marker-check-outline',
    'mdi-map-marker-distance',
    'mdi-map-marker-off',
    'mdi-map-marker-path',
    'mdi-menu',
    'mdi-message-alert',
    'mdi-message-text-lock',
    'mdi-minus',
    'mdi-paperclip',
    'mdi-parking',
    'mdi-paw',
    'mdi-pencil',
    'mdi-pencil-off',
    'mdi-plus',
    'mdi-plus-circle',
    'mdi-power-plug',
    'mdi-qrcode',
    'mdi-qrcode-scan',
    'mdi-radiobox-blank',
    'mdi-radiobox-marked',
    'mdi-receipt',
    'mdi-refresh',
    'mdi-reload',
    'mdi-send',
    'mdi-shape',
    'mdi-shield-check-outline',
    'mdi-sync-off',
    'mdi-test-tube',
    'mdi-text-box-multiple-outline',
    'mdi-toilet',
    'mdi-tools',
    'mdi-undo',
    'mdi-upload',
    'mdi-view-day',
    'mdi-view-list',
    'mdi-wallet',
    'mdi-wrench-clock',
  ]

  protected checkList = [
    {
      id: 'item1',
      title: 'ignition is off',
      text: 'I\'ve checked the control panel and there are no blinking lights.',
      icon: 'mdi-key-variant',
    },
    {
      id: 'item2',
      title: 'combobulator is off',
      description: 'I don\'t know what it is but I\'ve checked very carefully.',
      icon: 'mdi-lock-outline',
    },
    {
      id: 'item3',
      title: 'reaction control system is nominal',
      description: 'Also, I\'ve degaussed the flywheels thoroughly.',
      icon: 'mdi-flash-off',
    },
    {
      id: 'item4',
      title: 'I\'ve parked decently',
      description: 'I\'ve checked with mission control the stability of the orbit.',
      icon: 'mdi-parking',
    },
  ]

  protected checkListValues: Record<string, boolean> = {}
  protected splosh: SplashButtons[] = [
    {
      elements: [
        {
          id: 'FLOW',
          title: 'Flow',
          action: 'flow',
          description: 'hello world!',
          icon: 'human-greeting',
          options: 'hello_world',
          color: 'primary',
        },
        {
          id: 'PATH',
          title: 'Path',
          action: 'path',
          icon: 'cog',
          description: 'settings',
          options: 'settings',
          color: 'accent',
        },
      ],
    }, {
      elements: [
        {
          id: 'URL',
          title: 'Playmoove Website',
          action: 'url',
          description: '',
          options: 'https://playmoove.com/',
          color: 'secondary',
        },
        {
          id: 'HOME_MODE',
          title: 'Listing Mode',
          action: 'home_mode',
          description: '',
          options: 'listing',
          color: 'white',
        },
        {
          id: 'INFO',
          title: '',
          action: 'info',
          description: '<i aria-hidden="true" class="v-icon mdi mdi-information theme--dark" style="font-size: 24px;"></i>',
          options: 'you got info!',
          icon: 'info',
          color: 'black',
        },
      ],
    }, {
      elements: [
        {
          id: 'FFCAR',
          color: 'FFCAR',
          title: 'Free Floating - al minuto',
          description: 'Auto con prelievo e rilascio nelle aree verdi e arancioni.<br> Adatto per brevi spostamenti. Tariffa al minuto con carburante incluso.',
          icon: 'car',
          action: 'filters',
          options: {
            meta: {range_selector: true},
            groups: [
              {
                id: 'cars',
                items: [{
                  color: '#00598a',
                  enabled: true,
                  icon: 'img\\\/map\\\/markers\\\/CAR-FF-OK.png',
                  id: 'FFCAR',
                  name: 'Free-Floating',
                  vehicle_types: ['CAR'],
                  booking_mode: ['FF'],
                }],
              }, {
                id: 'park',
                title: 'Zone di parcheggio',
                items: [{
                  enabled: true,
                  id: 'parkings',
                  name: 'Stazioni',
                  small: true,
                  parkings: ['gE1D4R', 'Ym5LaB', 'Ma38mx', 'ZEdw40', '7m6vmv', 'dEDZmQ', 'nakXmY', '2Ebppm', '1m2dJE', 'v4zeym', 'l4yjYE', 'g4xevE', '1m2dzE'],
                }],
              },
            ],
          },
        },
      ],
    },
    {
      elements: [
        {
          id: 'RTCAR',
          color: 'RTCAR',
          title: 'Round Trip - a ora',
          description: 'Auto o Van con ritiro e rilascio da stesso parcheggio.<br>Tariffa oraria più chilometrica con carburante incluso.',
          icon: 'car',
          action: 'filters',
          options: {
            meta: {time_picker: true},
            groups: [
              {
                id: 'carsRT', items: [
                  {
                    color: '#ff6100',
                    enabled: true,
                    icon: 'img\\\/map\\\/markers\\\/CAR-RT-OK.png',
                    id: 'RTCAR',
                    name: 'Round Trip',
                    vehicle_types: ['CAR'],
                    booking_mode: ['RT'],
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ]

  protected get currentTab() {
    return !!this.debugTab ? Number(this.debugTab) : 0
  }

  protected set currentTab(tab: number) {
    this.setTab(tab)
  }

  protected get devModeOn() {
    return this.debugMode
  }

  protected set devModeOn(enabled: boolean) {
    this.setDebugMode(enabled)
  }

  protected get translationModeOn() {
    return this.translationMode
  }

  protected set translationModeOn(enabled: boolean) {
    this.setTranslationMode(enabled)
  }

  protected get flowNames() {
    const arr: string[] = []
    Object.keys(this.flowInputs).forEach((s) => {
      arr.push(s)
    })
    return arr
  }

  protected get selectedFlow() {
    if (this.flowNames && this.flowName && this.flowNames.includes(this.flowName)) {
      return this.flowInputs[this.flowName]
    }
    return null
  }

  protected get flowPages() {
    return this.selectedFlow ? this.selectedFlow.steps.length - 1 : null
  }

  protected get formattedLogs() {
    const result = this.logs.map((l) => {
      let timestamp: string | null
      let text: string

      if (l.includes('>>')) {
        [timestamp, text] = l.split('>>')
      } else {
        text = l
        timestamp = null
      }
      return {
        text,
        time: timestamp ? moment(Number(timestamp)).calendar(null, {
          sameDay: 'LTS',
          lastDay: 'DD/MM LTS',
          lastWeek: 'DD/MM LTS',
          sameElse: 'DD/MM LTS',
        }) : null,
        color: this.logColor(text),
      }
    })
    if (this.reversedLogs) {
      return result.reverse()
    }
    return result
  }

  protected get notchyNotches() {
    const getStyle = (prop: string) => getComputedStyle(document.documentElement).getPropertyValue(prop)
    return `${getStyle('--sat')} ${getStyle('--sar')} ${getStyle('--sab')} ${getStyle('--sal')}`
  }

  @Watch('selectedFlow', {immediate: true})
  protected onSelectedFlowChange() {
    this.flowPage = 0
  }

  protected changePage(step: number) {
    this.flowPage = step
  }

  protected mounted() {
    this.getPlans()
    this.$log('playmooooooooooooooooove', 0)
  }

  protected beforeDestroy() {
    EventBus.$off('dialogConfirm')
  }

  protected exitForward() {
    alert('exit forward')
  }

  protected exitBack() {
    alert('exit back')
  }

  protected gotUploadResponse(a: Attachment) {
    this.cameraResultToken = a.token
    this.uploadResultUrl = a.url
  }

  protected openLink(url: string) {
    cordova.InAppBrowser.open(url, '_system')
  }

  protected setChecklist(v: Record<string, boolean>) {
    this.checkListValues = v
  }

  protected gotPhoto(r: string) {
    this.cameraResult = r
    this.$log('got string', 0)
  }

  protected gotBlob(b: Blob) {
    this.$log('got blob', 0)
    blobToDataURL(b).then((r) => {
      this.cameraResult = (r as string)
    })
  }

  protected resetCameraResults() {
    this.cameraResult = null
    this.cameraResultToken = null
  }

  protected testLog() {
    this.$log('[test] all is fine.', 0)
    this.$log('[test] this is fine', 1)
    this.$log('[test] this is not fine', 2)
    this.$log('[test] EVERYTHING IS BURNING', 3)
  }

  protected copyLogs() {
    navigator.clipboard.writeText(JSON.stringify(this.logs, undefined, 2))
  }

  protected logColor(log: string) {
    const level = log.split('|')[0].trim()
    return (LogColorByLevel as { [key: string]: string })[level]
  }

  protected loginFromToken(token: string) {
    this.tokenSwitching = true
    this.switchProfile(token).finally(() => {
      this.tokenSwitching = false
    }).then(() => {
      this.token = ''
      this.$router.push({name: 'home'})
    })
  }

  protected openQRScanner() {
    this.$popup.open(QrScanner, {
      props: {
        qrImage: '', callback: (code: string) => {
          this.qrContent = code
        },
      }, fullscreen: true, hideTopbar: true,
    })
  }

  protected setSplash() {
    this.setSplashConfig(this.splosh)
    this.$popup.open(Splash, {hideTopbar: true})
  }

  protected reload() {
    this.$router.push('/').then(() => {
      location?.reload()
      this.$log('NUKED', 3)
    })
  }
}
